import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation, Pagination } from 'swiper'

const Blog = () => {
  return (
    <section className="blog">
      <div className="av-container">
        <div className="av-row">
          <div className="section-title">
            <h2 className="section-title__text">DICAS EM DESTAQUES</h2>
          </div>
          <p className="texto-blog">
            Se você é apaixonado por esportes igual a gente, fique de olho nas
            dicas e conselhos que nossos esportistas prepararam pra te ajudar a
            praticar seus esportes favoritos
          </p>
        </div>
      </div>
      <div className="av-container">
        <div className="av-row">
          <div className="section08-blog-post-content">
            <Swiper
              className="slick-slide-swiper-inverno"
              slidesPerView={1}
              pagination={{ clickable: true }}
              breakpoints={{
                1244: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1100: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 3,
                },
                425: {
                  slidesPerView: 1,
                },
                320: {
                  slidesPerView: 1,
                },
              }}
              lazy={{
                loadPrevNext: true,
                loadPrevNextAmount: 1,
              }}
              modules={[Navigation, Lazy, Pagination]}
            >
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/nova-colecao-olaian/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/beachwear-2022-saber-mais.png"
                      alt="Saber Mais | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        Saiba mais sobre a nova coleção
                      </p>
                      <p className="conteudo-post">
                        Você conhece a nova coleção de Beachwear? Ela foi
                        desenvolvida pelo nosso time de esportistas
                        especialistas que pesquisam, desenvolvem e testam nossos
                        produtos diretamente no ambiente de prática
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/como-escolher-seu-biquini-ou-maio-neste-verao/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/beachwear-2022-escolher-biquini.png"
                      alt="escolher biquini | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">Como escolher o seu biquini</p>
                      <p className="conteudo-post">
                        Conheça os principais benefícios dos nossos biquínis e
                        maiôs e escolha o que melhor se adapta ao seu corpo!
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/como-escolher-sua-bermuda-de-surf/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/beachwear-2022-escolher-bermuda.png"
                      alt="escolher bermuda | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        Como escolher a sua bermuda{' '}
                      </p>
                      <p className="conteudo-post">
                        Quando o frio chega, bate aquela tristeza de largar o
                        edredom quentinho e sair por aí trilhando, não é?
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="post">
                  <a href="https://souesportista.decathlon.com.br/como-escolher-uma-camiseta-para-a-pratica-de-surf/">
                    <img
                      src="https://decathlonpro.vteximg.com.br/arquivos/beachwear-2022-tops-solares.png"
                      alt="tops solares | Decathlon"
                    />
                    <div className="texto-post">
                      <p className="titulo-post">
                        Saiba mais sobre os tops solares
                      </p>
                      <p className="conteudo-post">
                        Nossos top solares foram desenvolvidos com tecido que
                        bloqueia 98% da radiação UVB e 95% da UVA
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog

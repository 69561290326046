const BrazilianCollection = () => {
  return (
    <section className="brazilian-collection">
      <h2>Coleção Brasilidades</h2>
      <p>
        Descubra os novos produtos de beachwear, desenvolvidos para oferecerem
        estabilidade, conforto, liberdade de movimento sem deixar de valorizar
        as estampas, o espírito tropical e alegre da coleção.
      </p>
    </section>
  )
}

export default BrazilianCollection

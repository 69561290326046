const BannerFull = () => {
  return (
    <section className="banner-full">
      <img
        className="desk"
        src="https://decathlonpro.vteximg.com.br/arquivos/banner-olaian-geral-desk.png"
        alt="Banner Beachwear 2022"
      />
      <img
        className="mob"
        src="https://decathlonpro.vteximg.com.br/arquivos/banner-olaian-geral-mobile.png"
        alt="Banner Beachwear 2022"
      />
    </section>
  )
}

export default BannerFull

import BannerFull from './BannerFull'
import Blog from './Blog'
import BrazilianCollection from './BrazilianCollection'
import ButtonsGender from './ButtonsGender'
import Categorias from './Categorias'
import './styles.scss'
import ThreeCards from './ThreeCards'

const BeachwearComponent = () => {
  return (
    <>
      <main className="main-beach-wear">
        <BannerFull />
        <ButtonsGender />
        <BrazilianCollection />
        <ThreeCards />
        <Categorias />
        <Blog />
      </main>
    </>
  )
}

export default BeachwearComponent

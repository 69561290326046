const ThreeCards = () => {
  return (
    <section className="three-cards">
      <a href="https://www.decathlon.com.br/collection?q=708&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-bikini-olaian.png"
          alt="bikinis olaian"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=710&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-tops-olaian.png"
          alt="tops olaian"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=709&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-bermudas-olaian.png"
          alt="bermudas olaian"
        />
      </a>
    </section>
  )
}

export default ThreeCards

const Categorias = () => {
  return (
    <section className="categorias">
      <div className="wrapper-title">
        <span>mais</span>
        <span>categorias</span>
      </div>
      <a href="https://www.decathlon.com.br/collection?q=712&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-pranchas-olaian.png"
          alt="pranchas e acessorios olaian"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=711&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/picto-ECO-fitness-cardio.png"
          alt="ECO olaian"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=720&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/picto-saude-fitness-cardio.png"
          alt="saude olaian"
        />
      </a>
    </section>
  )
}

export default Categorias

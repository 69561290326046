const ButtonsGender = () => {
  return (
    <section className="buttons-genders">
      <a href="https://www.decathlon.com.br/collection?q=706&O=OrderByTopSaleDESC#1">
        feminino
      </a>
      <a href="https://www.decathlon.com.br/collection?q=705&O=OrderByTopSaleDESC#1">
        masculino
      </a>
      <a href="https://www.decathlon.com.br/collection?q=707&O=OrderByTopSaleDESC#1">
        infantil
      </a>
    </section>
  )
}

export default ButtonsGender
